// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/hp/main_img_new.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n@media (min-width: 768px){\n    .main-banner{background: #2a3b4d url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 114% center no-repeat; background-size: 63%;}\n    .ar .main-banner{background: #2a3b4d url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -19% center no-repeat; background-size: 63%;}\n\n}\n\n@media (min-width: 960px){\n    .main-banner{background: #2a3b4d url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100% center no-repeat; }\n}\n\n@media (min-width: 1200px){\n    .main-banner{background: #2a3b4d url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 80% center no-repeat; }\n    .ar .main-banner{background: #2a3b4d url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 20% center no-repeat; }\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/hp/hp.css"],"names":[],"mappings":";;AAEA;IACI,aAAa,iFAA+E,EAAE,oBAAoB,CAAC;IACnH,iBAAiB,iFAA+E,EAAE,oBAAoB,CAAC;;AAE3H;;AAEA;IACI,aAAa,iFAA+E,EAAE;AAClG;;AAEA;IACI,aAAa,gFAA8E,EAAE;IAC7F,iBAAiB,gFAA8E,EAAE;AACrG","sourcesContent":["\n\n@media (min-width: 768px){\n    .main-banner{background: #2a3b4d url(../../images/hp/main_img_new.png) 114% center no-repeat; background-size: 63%;}\n    .ar .main-banner{background: #2a3b4d url(../../images/hp/main_img_new.png) -19% center no-repeat; background-size: 63%;}\n\n}\n\n@media (min-width: 960px){\n    .main-banner{background: #2a3b4d url(../../images/hp/main_img_new.png) 100% center no-repeat; }\n}\n\n@media (min-width: 1200px){\n    .main-banner{background: #2a3b4d url(../../images/hp/main_img_new.png) 80% center no-repeat; }\n    .ar .main-banner{background: #2a3b4d url(../../images/hp/main_img_new.png) 20% center no-repeat; }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
